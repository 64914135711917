import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import {FastBlockInfoDto, RateFastInfo} from '../../types/types';
import {getExchangeRateFastInfo} from "../../api/controllers/web-controller";

const defaultFastBlockInfo: FastBlockInfoDto = {
	weather: { temperature: 25, cloud_cover: 0, precipitation_type: 0 },
	expensive_condition: 0,
	rates: [
		{ code: 'USD', value: 85.7 },
		{ code: 'EUR', value: 92.83 },
	], // max 2 items
	location: 'Москва',
};

const FastBlockInfo = () => {
	const [fastInfo, setFastInfo] =
		useState<RateFastInfo| undefined>();

	useState(() => {
		getExchangeRateFastInfo().then(response => {
			setFastInfo(response.data);
		}).catch(err => {
		});
	})

	return (
		<Box>
			<Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						paddingLeft: '12px',
						paddingRight: '12px',
					}}
				>
					<span>Последние новости</span>
					<Box sx={{ display: 'flex' }}>
						<span style={{ marginRight: '12px' }}>Курс валют</span>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							{
								fastInfo != null && fastInfo.list.filter(r => r.id == "EUR" || r.id == "USD").map(item => (
									<span>
										{item.id} {item.value}
									</span>
								))


							}
						</Box>
					</Box>
				</Box>
			</Typography>
		</Box>
	);
};

export default FastBlockInfo;
